<template>
  <div class="beUpdating">
    <div class="hint-img">
      <img src="../../../public/static/images/vip/gengxintishi.png" alt="">
      <p>为了您更好的体验，系统将于晚上8:00~8:30停服更新！</p>
      <p>给您带来的不变敬请谅解</p>
    </div>
    <div class="toVip" @click="toVip"></div>
  </div>
</template>

<script>
export default {
  name: "beUpdating",
  data() {
    return {
    }
  },
  mounted() {
    this.$toast.loading({
      message: '系统更新中...',
      forbidClick: false,
      closeOnClickOverlay: true,
      closeOnClick: true,
      loadingType: 'spinner',
      duration: 0
    });
  },
  methods: {
    toVip() {
      this.$router.push("/vip")
    }
  }
}
</script>

<style lang="scss" scoped>
.beUpdating {
  height: 100vh;
  background: #fff;
  position: relative;
  
  .hint-img {
    height: 320px;
    text-align: center;
    display: flex;
    flex-direction: column;
    background: #fbfbfb;
    justify-content: center;
    align-items: center;

    img {
      width: 40%;
      margin-bottom: 20px;
    }
    .iconshenfenyanzheng {
      font-size: 100px;
      color: #6096DD;
    }
  }
  .toVip {
    width: 100px;
    height: 50px;
    position: absolute;
    top: 0;
  }

  .cont {
    padding: 0 10px;
    .marginTop {
      margin-top: 20px;
    }
  }
}
</style>
